<div class="card">
  <div class="card-body">
    <div class="card-columns scrollable">
      <div class="card" *ngIf="hasAdministration$ | async">
        <div class="card-header">
          <i class="fa fa-wrench site-secondaire-color mg-r-5"></i>RÉFÉRENTIEL
        </div>
        <div class="card-body">
          <p-panelMenu [model]="referentielItems"></p-panelMenu>
        </div>
      </div>

      <div class="card " *ngIf="hasGestionFournisseurs$ | async">
        <div class="card-header">
          <i class="fa fa-truck site-secondaire-color mg-r-5"></i>FOURNISSEURS
        </div>
        <div class="card-body">
          <yo-button class="bureau-button"
                     routerLink="/gestion-fournisseurs"
                     label="Voir la liste des fournisseurs"
                     iconClass="fas fa-chevron-right"
                     buttonClass="main">
          </yo-button>

          <yo-button class="bureau-button"
                     routerLink="/gestion-fournisseurs/articles/article-affecte"
                     label="Mise à jour des articles"
                     iconClass="fas fa-chevron-right"
                     buttonClass="main">
          </yo-button>
          <yo-button class="bureau-button"
                     routerLink="/gestion-marche"
                     label="Marchés"
                     iconClass="fas fa-chevron-right"
                     buttonClass="main">
          </yo-button>
        </div>
      </div>

      <div class="card " *ngIf="hasUniteDeProduction$ | async">
        <div class="card-header">
          <i class="fa fa-industry site-secondaire-color mg-r-5"></i>UNITÉS DE PRODUCTION
        </div>
        <div class="card-body">
          <yo-button class="bureau-button" routerLink="/gestion-unitesproduction"
                     label="Voir les unités de production"
                     iconClass="fas fa-chevron-right"
                     buttonClass="main"
                     tooltip="Unités de production">
          </yo-button>
        </div>
      </div>

      <div class="card " *ngIf="gestionProduitsIapro || gestionProduitsIprod">
        <div class="card-header">
          <i class="fas fa-carrot  mg-r-5 site-secondaire-color"></i>PRODUITS
        </div>
        <div class="card-body">
          <yo-button class="bureau-button" routerLink="/gestionproduits"
                     label="Voir les produits"
                     iconClass="fas fa-chevron-right"
                     buttonClass="main">
          </yo-button>
        </div>
      </div>

      <div class="card " *ngIf="hasGestionMenus$ | async">
        <div class="card-header">
          <i class="fas fa-utensils mg-r-5 site-secondaire-color"></i> MENUS
        </div>
        <div class="card-body">
          <yo-button class="bureau-button" routerLink="/gestiongemrcn"
                     label="Modèles de menus"
                     iconClass="fas fa-chevron-right"
                     buttonClass="main">
          </yo-button>
          <yo-button class="bureau-button" routerLink="/gestioncontrats"
                     label="Offres alimentaires"
                     iconClass="fas fa-chevron-right"
                     buttonClass="main">
          </yo-button>
          <yo-button class="bureau-button" routerLink="/gestionmenus"
                     label="Planning des menus"
                     iconClass="fas fa-chevron-right"
                     buttonClass="main">
          </yo-button>
        </div>
      </div>

      <div class="card " *ngIf="hasGestionClients$ | async">
        <div class="card-header">
          <i class="fas fa-users mg-r-5 site-secondaire-color"></i> CLIENTS
        </div>
        <div class="card-body">
          <yo-button class="bureau-button" *ngIf="hasGestionPlc$ | async"
                     routerLink="/gestion-plc/menu-plc"
                     label="Planning des effectifs"
                     iconClass="fas fa-chevron-right"
                     buttonClass="main">
          </yo-button>
          <yo-button class="bureau-button" *ngIf="hasGestionPlc$ | async"
                     routerLink="/gestion-clients"
                     label="Clients et points de livraison"
                     iconClass="fas fa-chevron-right"
                     buttonClass="main">
          </yo-button>
          <yo-button class="bureau-button" *ngIf="hasPortailUtilisateur$ | async"
                     routerLink="/gestion-plc/portail-plc"
                     label="Portail de saisie des effectifs"
                     iconClass="fas fa-chevron-right"
                     buttonClass="main">
          </yo-button>
        </div>
      </div>

      <div class="card " *ngIf="hasGestionBesoins$ | async">
        <div class="card-header">
          <i class="fas fa-shopping-basket site-secondaire-color mg-r-5"></i> BESOINS
        </div>
        <div class="card-body">
          <yo-button class="bureau-button" routerLink="/gestion-listes-besoins/lb/listes-de-besoins"
                     label="Voir les besoins"
                     iconClass="fas fa-chevron-right"
                     buttonClass="main">
          </yo-button>
        </div>
      </div>

      <div class="card " *ngIf="(hasGestionCommandes$ | async) || (hasCommandesTableauBord$ | async)">
        <div class="card-header">
          <i class="fa fa-shopping-cart site-secondaire-color mg-r-5"></i>COMMANDES FOURNISSEURS
        </div>
        <div class="card-body">
          <yo-button class="bureau-button" *ngIf="hasCommandesTableauBord$ | async"
                     routerLink="/gestion-commandes-fournisseurs/bc/tableau-de-bord"
                     label="Tableau de bord"
                     iconClass="fas fa-chevron-right"
                     buttonClass="main">
          </yo-button>
          <yo-button class="bureau-button" *ngIf="hasGestionCommandes$ | async"
                     routerLink="/gestion-commandes-fournisseurs/bc/propositions"
                     label="Propositions de commande"
                     iconClass="fas fa-chevron-right"
                     buttonClass="main">
          </yo-button>
          <yo-button class="bureau-button" *ngIf="hasGestionCommandes$ | async"
                     routerLink="/gestion-commandes-fournisseurs/bc/refusees"
                     label="Commandes refusées"
                     iconClass="fas fa-chevron-right"
                     buttonClass="main">
          </yo-button>
          <yo-button class="bureau-button" *ngIf="hasGestionCommandes$ | async"
                     routerLink="/gestion-commandes-fournisseurs/bc/a-valider"
                     label="Commandes à valider"
                     iconClass="fas fa-chevron-right"
                     buttonClass="main">
          </yo-button>
          <yo-button class="bureau-button" *ngIf="hasStocks$ | async"
                     routerLink="/gestion-commandes-fournisseurs/bc/a-receptionner"
                     label="Commandes à réceptionner"
                     iconClass="fas fa-chevron-right"
                     buttonClass="main">
          </yo-button>
          <yo-button class="bureau-button" *ngIf="hasStocks$ | async"
                     routerLink="/gestion-commandes-fournisseurs/bc/receptions"
                     label="Commandes réceptionnées"
                     iconClass="fas fa-chevron-right"
                     buttonClass="main">
          </yo-button>
          <yo-button class="bureau-button" *ngIf="hasFacturation$ | async"
                     routerLink="/gestion-commandes-fournisseurs/bc/facturees"
                     label="Commandes facturées"
                     iconClass="fas fa-chevron-right"
                     buttonClass="main">
          </yo-button>
        </div>
      </div>

      <div class="card " *ngIf="hasStocks$ | async">
        <div class="card-header">
          <i class="fas fa-cubes site-secondaire-color mg-r-5"></i>STOCKS
        </div>
        <div class="card-body">
          <yo-button class="bureau-button"
                     routerLink="/gestion-stocks/stocks/matieres-premieres"
                     label="Matières premières"
                     iconClass="fas fa-chevron-right"
                     buttonClass="main">
          </yo-button>
          <yo-button class="bureau-button"
                     routerLink="/gestion-stocks/mouvements/grille-mouvements"
                     label="Mouvements"
                     iconClass="fas fa-chevron-right"
                     buttonClass="main">
          </yo-button>
          <yo-button class="bureau-button"
                     routerLink="/gestion-stocks/stocks/inventaire"
                     label="Inventaire"
                     iconClass="fas fa-chevron-right"
                     buttonClass="main">
          </yo-button>
        </div>
      </div>

      <div class="card" *ngIf="hasGestionProduction$ || hasGestionIDistri$ | async">
        <div class="card-header">
          <i class="fas fa-newspaper site-secondaire-color mg-r-5"></i>PRODUCTION
        </div>
        <div class="card-body">
          <yo-button class="bureau-button"
                     routerLink="/gestion-production/pp/plans-de-production"
                     label="Plans de production"
                     iconClass="fas fa-chevron-right"
                     buttonClass="main">
          </yo-button>
          <yo-button class="bureau-button"
                     routerLink="/gestion-production/organisations-tournees"
                     label="Organisations de tournées"
                     iconClass="fas fa-chevron-right"
                     buttonClass="main">
          </yo-button>

          <div class="card " *ngIf="hasGestionIDistri$ | async">
            <div class="card-header">
              <i class="fas fa-boxes site-secondaire-color mg-r-5"></i>CONDITIONNEMENTS
            </div>
            <div class="card-body">
              <yo-button class="bureau-button"
                         routerLink="/gestion-conditionnements/modeles-plats"
                         label="Modèles de plats"
                         iconClass="fas fa-chevron-right"
                         buttonClass="main">
              </yo-button>
              <yo-button class="bureau-button"
                         routerLink="/gestion-conditionnements/modeles-plc"
                         label="Modèles Points de livraison"
                         iconClass="fas fa-chevron-right"
                         buttonClass="main">
              </yo-button>
            </div>
          </div>
        </div>
      </div>

      <div class="card " *ngIf="hasCmdFacturation$ | async">
        <div class="card-header">
          <i class="fas fa-boxes site-secondaire-color mg-r-5"></i>FACTURATION
        </div>
        <div class="card-body">
          <p-panelMenu [model]="facturationItems"></p-panelMenu>
        </div>
      </div>
    </div>
  </div>
</div>

