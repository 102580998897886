<dx-data-grid
    #grid
    height="100%"
    [dataSource]="lignes"
    keyExpr="id"
    noDataText="Pas de données"
    showBorders="true"
    showRowLines="true"
    allowColumnResizing="true"
    rowAlternationEnabled="true"
    columnResizingMode="widget"
    (onToolbarPreparing)="onToolbarPreparing($event)">

    <dxo-selection
            selectAllMode="allPages"
            showCheckBoxesMode="always"
            mode="multiple">
    </dxo-selection>

    <dxo-column-chooser mode="select" [enabled]="true"></dxo-column-chooser>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxo-pager
            [showPageSizeSelector]="true"
            [showNavigationButtons]="false"
            [allowedPageSizes]="[20, 50, 100, 200]"
            [visible]="true"
            [showInfo]="true"
            infoText="{2} ligne(s) de paramétrage">
    </dxo-pager>

    <dxi-column
            caption="Point de facturation"
            dataField="pointFacturationLibelle">
    </dxi-column>
    <dxi-column
            caption="Point de livraison"
            dataField="pointLivraisonLibelle">
    </dxi-column>
    <dxi-column
            caption="Offre alimentaire"
            dataField="offreAlimentaireLibelle">
    </dxi-column>
    <dxi-column
            caption="Prestation"
            dataField="prestationLibelle">
    </dxi-column>
    <dxi-column
            caption="Repas"
            dataField="repasLibelle">
    </dxi-column>
    <dxi-column
            caption="Régime"
            dataField="regimeLibelle">
    </dxi-column>
    <dxi-column
            caption="Élément facturable"
            dataField="elementFacturableLibelle">
    </dxi-column>
    <dxi-column
            caption="Taxe"
            dataField="taxeLibelle">
    </dxi-column>
    <dxi-column
            caption="Prix de vente HT"
            dataField="prixVenteHT"
            alignment="right"
            cellTemplate="prixTemplate">
    </dxi-column>

    <div *dxTemplate="let cell of 'prixTemplate'" class="prix-container">
        {{ cell?.row?.data?.prixVenteHT ? cell.row.data.prixVenteHT.toFixed(3) : 0 }} €
    </div>

    <div *dxTemplate="let cell of 'beforeTemplate'">
        <ng-content select="[data-position='left']"></ng-content>
    </div>

    <div *dxTemplate="let cell of 'afterTemplate'">
        <ng-content select="[data-position='right']"></ng-content>
    </div>

    <ng-content></ng-content>

</dx-data-grid>

