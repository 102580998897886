import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {LigneParametrageFacturation} from "../../../../core/models/facturation/client/ligne-parametrage-facturation";
import {ToolbarPreparingEvent} from "devextreme/ui/data_grid";
import {DxDataGridComponent} from "devextreme-angular";

@Component({
    selector: 'yo-ligne-parametrage-data-grid',
    templateUrl: './ligne-parametrage-data-grid.component.html',
    styleUrls: ['./ligne-parametrage-data-grid.component.scss']
})
export class LigneParametrageDataGridComponent implements OnInit, OnDestroy {

    @ViewChild("grid") grid : DxDataGridComponent;

    @Input() lignes: LigneParametrageFacturation[];

    @Output() onEdit: EventEmitter<LigneParametrageFacturation> = new EventEmitter();
    @Output() onSave: EventEmitter<LigneParametrageFacturation[]> = new EventEmitter();
    @Output() onDelete: EventEmitter<LigneParametrageFacturation[]> = new EventEmitter();

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    onToolbarPreparing($event: ToolbarPreparingEvent) {
        $event.toolbarOptions.items.unshift(
            {
                location: "before",
                template: "beforeTemplate"
            },
            {
                location: "after",
                template: "afterTemplate"
            }
        );
    }

    getSelectedData(): any[] {
        return this.grid.selectedRowKeys;
    }

}
