<p-card>
  <div class="card-size">

    <div class="custom-toolbar">
      <div class="custom-toolbar-element custom-button-container-left">
        <yo-button
          iconClass="fas fa-cog"
          label="GÉRER LA GRILLE"
          tooltip="Gérer la grille"
          (onClick)="openCreateRowParametrage()"
          [disabled]="!hasIDistri"
        >
        </yo-button>
        <yo-button
          iconClass="fas fa-compress-alt"
          buttonClass="cta-inner-secondary"
          tooltip="Réduire tous les éléments du tableau"
          (onClick)="collapseGrid()"
          [disabled]="!isResizableElementInGrid()"
        >
        </yo-button>
        <yo-button
          iconClass="fas fa-expand-alt"
          buttonClass="cta-inner-secondary"
          tooltip="Étendre tous les éléments du tableau"
          (onClick)="expendGrid()"
          [disabled]="!isResizableElementInGrid()"
        >
        </yo-button>
      </div>
      <div class="custom-toolbar-element custom-button-container-right">
        <yo-button
          tooltip="Enregistrer"
          type="submit"
          iconClass="fas fa-save"
          buttonClass="cta-success"
          (onClick)="save()"
          [disabled]="!hasIDistri && gridParametrageToSave.length > 0">
        </yo-button>
      </div>
    </div>

    <dx-data-grid
      [dataSource]="gridParametrage"
      keyExpr="id"
      [height]="utils.getWindowAvailableHeight(382)"
      width="100%"
      [hoverStateEnabled]="true"
      [allowColumnResizing]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      (onCellClick)="onCellClick($event)"
      (onCellPrepared)="onCellPrepared($event)"
      (onRowPrepared)="onRowPrepared($event)"
      #grid>
      <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
      <dxi-column dataField="mcpLabel" caption="Test Modèle de conditionnement plat" [width]="250" [groupIndex]="0"
                  [filterOperations]="['contains']"
                  [allowSearch]="true" [fixed]="true" fixedPosition="left">
      </dxi-column>
      <dxi-column dataField="conditionnementLabel" caption="Conditionnement" [width]="170"
                  [filterOperations]="['contains']"
                  [allowFiltering]="true" [allowSearch]="false" [fixed]="true" fixedPosition="left">
      </dxi-column>
      <dxi-column dataField="varianteLabel" caption="Variante" [width]="170"
                  [filterOperations]="['contains']"
                  [allowFiltering]="true" [allowSearch]="false" [fixed]="true" fixedPosition="left">
      </dxi-column>
      <dxi-column *ngFor="let prestation of prestationList"
                  dataField="prestation.id"
                  cellTemplate="prestationCellTemplate"
                  [caption]="prestation.libelle">
      </dxi-column>

      <dxo-summary>
        <dxi-total-item
          *ngFor="let prestation of prestationList"
          name="SelectedRowsSummary"
          showInColumn="prestation">
        </dxi-total-item>
      </dxo-summary>

      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-sorting mode="multiple"></dxo-sorting>

      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} conditionnements plc">
      </dxo-pager>

      <dxo-search-panel
        [visible]="true"
        [searchVisibleColumnsOnly]="true"
      ></dxo-search-panel>

      <div
        [class]="cell.row.data.prestations[cell.columnIndex][cell.row.data.id].actif ? 'prestation-enabled' : 'prestation-disabled'"
        [title]="getToolTipPrestation(cell)"
        *dxTemplate="let cell of 'prestationCellTemplate'">
        <span [title]="getToolTipPrestation(cell)">&nbsp;</span>
      </div>

    </dx-data-grid>

  </div>
</p-card>

<yo-mp-row-parametrage-plc></yo-mp-row-parametrage-plc>
