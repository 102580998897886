// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[production]6.1.1-11157(24b97b1ca3)-C22/01/2025-10:11:57-B22/01/2025-10:17:07' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[production]6.1.1-11157(24b97b1ca3)-C22/01/2025-10:11:57-B22/01/2025-10:17:07",
  branch: "production",
  latestTag: "6.1.1",
  revCount: "11157",
  shortHash: "24b97b1ca3",
  longHash: "24b97b1ca39bfef295b82141c582917f6398838e",
  dateCommit: "22/01/2025-10:11:57",
  dateBuild: "22/01/2025-10:17:07",
  buildType: "Ansible",
  } ;
