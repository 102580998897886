import {Injectable} from '@angular/core';
import {ClientDTO} from '../../dtos/client-dto';
import {GenericHandler} from '../generics/generic-handler';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {ObjectDTO} from '../../dtos/object-dto';
import {catchError} from 'rxjs/operators';
import {PointDeLivraisonDTO} from '../../dtos/point-de-livraison-d-t-o';
import * as moment from 'moment';
import {GenericRequestSupplier, Predicat, Search, Sort} from '../../suppliers/generics/generic-request-supplier';
import {ContratMenuConviveDTO} from '../../dtos/contratmenuconvive-dto';
import {startCase as _startCase} from 'lodash';
import {PlcRepas, PlcWeek} from '../../../gestion-plc/menu-plc/menu-plc-resolver.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {ContratMenuConviveRepasPlcDateDTO} from '../../dtos/cmcr-plc-date-dto';
import {FormGroup} from '@angular/forms';
import {PREDICAT_OPERATOR, PREDICAT_TYPE} from "../../constants";
import {HttpService} from "../technique/http.service";
import ModeleConditionnementPlcDTO from "../../dtos/conditionnement/plc/modele-conditionnement-plc-dto";
import {SearchSupplierWrapper} from "../../suppliers/wrappers/search-supplier-wrapper";
import {GraphQLService} from "../technique/graphql.service";


export const URL_POST_SAVE_POINT_DE_LIVRAISON = `dolrest/gestion-clients/pointDeLivraison/save/one`;
export const URL_GET_PLCLIST_BY_PRESTATION = `dolrest/gestioncontrats/prestation/points-de-livraison`;
export const URL_POST_ATTACH_PLCLIST_TO_PRESTATION = `dolrest/gestioncontrats/prestation/points-de-livraison/attach`;
export const URL_GET_PLCLIST = `dolrest/gestion-clients/points-de-livraison`;
export const URL_GET_PLC_WITHOUT_MCPLC_LIST = `dolrest/gestion-clients/plc-without-mcplc`;
export const URL_GET_PLC_WITH_MCPLC_LIST = `dolrest/gestion-clients/plc-with-mcplc/`;
export const URL_GET_PLCLIST_PRESTATIONS = `dolrest/gestion-clients/points-de-livraison/prestations`;
export const URL_GET_WEEK_PLC = `dolrest/gestion-clients/point-de-livraison/semaine`;
export const URL_POST_PLC_REPAS = `dolrest/gestion-clients/point-de-livraison/repas`;
export const URL_POST_MENUS_COMPOS_PLC_DATES = `dolrest/gestion-clients/point-de-livraison/months/dates-menus`;
export const URL_PLC_LINK_TO_USER_BY_ENV_PLC = `dolrest/portail/point-de-livraison/link-to-user-by-env-plc`;

@Injectable({
  providedIn: 'root'
})
export class PointDeLivraisonService extends GenericHandler<PointDeLivraisonDTO> {

  private subjectPlcWeek = new Subject<PlcWeek>();
  plcWeek$ = this.subjectPlcWeek.asObservable();

  private subjectPlcRepas = new Subject<PlcRepas>();
  plcRepas$ = this.subjectPlcRepas.asObservable();

  private subjectMenuPlcMonthChange = new Subject();
  menuPlcMonthChange$ = this.subjectMenuPlcMonthChange.asObservable();

  private subjectRefreshGrid = new Subject();
  refreshGrid$ = this.subjectRefreshGrid.asObservable();

  private subjectRefreshRepas = new Subject();
  refreshRepas$ = this.subjectRefreshRepas.asObservable();

  // 1 = effectif previsionnel, 2=effectif fabrication, 3 = effectif facturé
  private subjectTypeEffectif = new BehaviorSubject(1);
  typeEffectif$ = this.subjectTypeEffectif.asObservable();

  private subOpenDialogBindPlcsWithMcPlc = new Subject<ModeleConditionnementPlcDTO>();
  openDialogBindPlcsWithMcPlc$ = this.subOpenDialogBindPlcsWithMcPlc.asObservable();

  /**
   * permet d'indiquer aux composants repas-plc quelle est la cellule sélectionnée.
   * en effet, on peut afficher plusieurs repas plc en meme temps (dejeuner, gouter, diner...)
   * mais on ne veut qu'une seule cellule sélectionnée pour l'ensemble de ces repas-plc
   * il faut un moyen de deselectionner la cellule precedemment selectionnée dans un repas plc différent de celui actuel
   */
  private subjectSelectedCell = new Subject();
  selectedCell$ = this.subjectSelectedCell.asObservable();

  static subjectLibelle = new Subject();
  libelle$ = PointDeLivraisonService.subjectLibelle.asObservable();


  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title,
              private gds: GenericDatagridService,
              private httpSvc: HttpService,
              private graphQlSvc: GraphQLService
  ) {

    super(utils, auth2Svc, router, http, title);
  }

  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());


  getHelp = (): DialogMsgSupplier => undefined;

  getSort = (): string[] => ['libelle,asc'];

  getOas = (): boolean => undefined;

  getTitle = (): string => 'GESTION DES POINTS DE LIVRAISON';

  getCreateNewObjectLabel = (): string => 'CRÉER UN POINT DE LIVRAISON';


  getEntityName = (): string => 'pointDeLivraison';

  getFields = (dto: PointDeLivraisonDTO): FormFieldBaseSupplier<any>[] => {

    if (this.utils.isNullOrEmpty(dto)) {
      dto = new PointDeLivraisonDTO();
    }

    let formFields: FormFieldBaseSupplier<any>[] = [


      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        readonly: !this.canModify(dto),
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        order: 2,
        linkedFieldFn: this.onChangeLibelle
      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        readonly: !this.canModify(dto),
        value: dto.code,
        maxLength: 50,
        required: true,
        order: 4
      }),


      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        readonly: !this.canModify(dto),
        value: dto.actif,
        required: false,
        order: 6
      }),


      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 7
      }),


      new FormFieldCheckboxSupplier({
        key: 'livraisonDirecte',
        label: 'Livraison directe',
        readonly: !this.canModify(dto),
        value: dto.livraisonDirecte,
        required: false,
        order: 8
      })


    ];

    return formFields.sort((a, b) => a.order - b.order);

  };


  getAllFromEnvironnement = (): void => {
  };


  createEmptyDTO = (): PointDeLivraisonDTO => {

    const pdl = new PointDeLivraisonDTO();
    pdl.libelle = 'NOUVEAU POINT DE LIVRAISON';
    pdl.actif = true;
    pdl.code = 'PDL-' + moment(new Date()).format('YYYYMMDDHHmmss');

    return pdl;
  };


  getEditObjectLabel = (data: ObjectDTO): string => "";

  findByClientsIds = (ids: number[]):
    GenericRequestSupplier => {

    const grs = new GenericRequestSupplier(this.getEntityName());

    const search = new Search();
    search.predicats = [];

    const predicat = new Predicat();
    predicat.values = [];
    predicat.path = 'pointdelivraison.client.id';
    predicat.operator = PREDICAT_OPERATOR.In;
    predicat.type = PREDICAT_TYPE.Integer;
    predicat.ids = ids;

    search.predicats.push(predicat);
    const sort = new Sort();
    sort.dir = 'asc';
    sort.luceneSortType = 'string';
    sort.path = 'pointdelivraison.libelle';
    search.sorts = [];
    search.sorts.push(sort);
    grs.search = search;
    return grs;
  }

  filterByClient = (client: ClientDTO): GenericRequestSupplier => {

    const grs = new GenericRequestSupplier(this.getEntityName());
    const search = new Search();
    search.predicats = [];

    const predicat1 = new Predicat();

    predicat1.path = 'pointdelivraison.client.id';
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type = PREDICAT_TYPE.Integer;
    predicat1.value = client.id + '';

    search.predicats.push(predicat1);

    const sort = new Sort();
    sort.dir = 'asc';
    sort.luceneSortType = 'string';
    sort.path = 'pointdelivraison.libelle';
    search.sorts = [];
    search.sorts.push(sort);

    grs.search = search;


    return grs;
  };


  save = (client: ClientDTO, pdl: PointDeLivraisonDTO) => this.httpSvc.post(URL_POST_SAVE_POINT_DE_LIVRAISON + `?idClient=${client.id}`, pdl);

  getPlcListByPrestation = (idContratMenuConvive: number) => this.httpSvc.get(URL_GET_PLCLIST_BY_PRESTATION + `?idContratMenuConvive=${idContratMenuConvive}`);

  /**
   * Enelver une liste de plcs d'une prestation
   * @param cmc
   * @param selectedPointsDeLivraison
   */
  detachFromPrestation = (cmc: ContratMenuConviveDTO, selectedPointsDeLivraison: PointDeLivraisonDTO[]) => {

    let url = `dolrest/gestioncontrats/prestation/${cmc.id}/points-de-livraison/detach`;

    return this.http.post(url, selectedPointsDeLivraison)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  };

  /**
   * Récupérer la liste des plc auquel l'utilisateur a droit (on verifie que  le site du plc fait partie des sites de l'utilisateur connecté )
   */
  getPlcList = () => this.httpSvc.get(URL_GET_PLCLIST);

  findAllPlcList = (idClient: number = 0, onlyPlcNotRelatedToPointFacturation: boolean = false, idsPlcToExclude: number[]): Observable<any> => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    return this.graphQlSvc.sendQuery(`
      {
        allPointLivraison(filters: {
          siteIds: [${idsSites}],
          clientId: ${idClient},
          onlyPlcNotRelatedToPointFacturation: ${onlyPlcNotRelatedToPointFacturation},
          idsPlcToExclude: [${idsPlcToExclude}]
        }) {
            id,
            site {
              id,
              libelle
            }
            libelle,
            code,
            actif,
            adresse {
              id
            },
            contact1 {
              id,
              nom,
              prenom
            },
            contact2 {
              id,
              nom,
              prenom
            }
        }
      }`);
  }

  getPlcWithoutMcPlcList = (page: number, pageSize: number, ssw: SearchSupplierWrapper) => {
    const endUrl = page !== null && page !== undefined && pageSize !== null && pageSize !== undefined ? `?page=${page}&size=${pageSize}` : '';
    return this.httpSvc.post(`${URL_GET_PLC_WITHOUT_MCPLC_LIST}${endUrl}`, ssw);
  };

  getPlcWithMcPlcList = (mcPlcId?: number) => this.httpSvc.get(mcPlcId ? `${URL_GET_PLC_WITH_MCPLC_LIST}${mcPlcId}` : URL_GET_PLC_WITH_MCPLC_LIST);

  /**
   * Attacher des points de livraison à une prestation
   *
   * @param contratMenuConvive
   * @param selectedPointsDeLivraisonToAdd
   */
  attachToPrestation = (contratMenuConvive: ContratMenuConviveDTO, selectedPointsDeLivraisonToAdd: PointDeLivraisonDTO[]) => {

    const fd = new FormData();
    fd.set('idContratMenuConvive', contratMenuConvive.id + '');
    fd.set('idsPointDeLivraison', selectedPointsDeLivraisonToAdd ? selectedPointsDeLivraisonToAdd.map(item => item.id).join(',') : '');

    return this.http.post(URL_POST_ATTACH_PLCLIST_TO_PRESTATION, fd)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  };

  /**
   * récupérer les points de livraison rattachés aux prestations qui sont accessibles à l'utilisateur connecté
   *
   * @param contratMenuConvive
   * @param selectedPointsDeLivraisonToAdd
   */
  getPlcListBySitesPrestations = () => {

    return this.http.get(URL_GET_PLCLIST_PRESTATIONS)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  };

  getPlcListLinkToUserByEnvPlc = () => this.httpSvc.get(URL_PLC_LINK_TO_USER_BY_ENV_PLC);


  loadPlcWeek = (weekMonday: Date, weekSunday: Date, selectedPlc: PointDeLivraisonDTO) => {

    const dateDebut = this.utils.getYYYYMMDD(moment(weekMonday));
    const dateFin = this.utils.getYYYYMMDD(moment(weekSunday));
    const idPlc = selectedPlc ? selectedPlc.id + '' : '';

    return this.http.get(URL_GET_WEEK_PLC, {
      params: new HttpParams()
        .set('dateDebut', dateDebut)
        .set('dateFin', dateFin)
        .set('idPlc', idPlc)

    })
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  };

  loadPlcRepas = (cmcrPlcDate: ContratMenuConviveRepasPlcDateDTO) => {

    return this.http.post(URL_POST_PLC_REPAS, cmcrPlcDate)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  };

  announcePlcWeek = (plcWeek: PlcWeek) => {
    this.subjectPlcWeek.next(plcWeek);
  };

  announcePlcRepas = (plcRepas: PlcRepas) => {
    this.subjectPlcRepas.next(plcRepas);
  };

  announceTypeEffectif = (selectedMode: number) => {
    this.subjectTypeEffectif.next(selectedMode);
  };

  openDialogBindPlcsWithMcPlc = (mcPlc: ModeleConditionnementPlcDTO) => {
    this.subOpenDialogBindPlcsWithMcPlc.next(mcPlc);
  };


  onChangeLibelle = (value: string, form: FormGroup, fields: FormFieldBaseSupplier<any>[], object: any) => {
    PointDeLivraisonService.subjectLibelle.next(object);
  };

  announceMenuPlcMonthChange = $event => {
    this.subjectMenuPlcMonthChange.next($event);
  };

  getMonthDates = (event, startDate: Date, selectedPlc: PointDeLivraisonDTO, nbMonths: number) => {

    const momentDate = moment(startDate);
    let startMonth: number = momentDate.month() + 1;
    let startYear: number = momentDate.year();

    // console.log('getMonthDates');

    // si ca vient du onMonthChange kjlnhjk
    if (event && event.month && event.year) {
      startMonth = event.month;
      startYear = event.year;
    }

    const idPlc = selectedPlc ? selectedPlc.id + '' : '-1';


    const fd: FormData = new FormData();
    fd.set('startMonth', startMonth + '');
    fd.set('startYear', startYear + '');
    fd.set('idPlc', idPlc);
    fd.set('nbMonths', nbMonths + '');

    return this.http.post(URL_POST_MENUS_COMPOS_PLC_DATES, fd)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );
  };

  announceSelectedCell = (selectedCell: ContratMenuConviveRepasPlcDateDTO) => {
    this.subjectSelectedCell.next(selectedCell);
  };

  announceRefreshGrid = (plc: PointDeLivraisonDTO): void => {
    this.subjectRefreshGrid.next(plc);
  };

  announceRefreshRepas = (): void => {
    this.subjectRefreshRepas.next();
  };
}


