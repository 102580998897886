import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {Subscription} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {PointFacturationDto} from "../../../../core/dtos/facturation/clients/points-facturation/point-facturation-dto";
import {MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {ToastService} from "../../../../core/services/technique/toast.service";
import {UtilsService} from "../../../../core/utils/utils.service";
import {FacturationClientService} from "../../../../core/services/entities/facturation-client.service";
import {GenericFormService} from "../../../../core/services/generics/generic-form.service";
import {AdresseDTO} from "../../../../core/dtos/adresse-dto";
import {ContactDTO} from "../../../../core/dtos/contact-dto";
import {PointDeLivraisonService} from "../../../../core/services/entities/point-de-livraison.service";
import {PointDeLivraisonDTO} from "../../../../core/dtos/point-de-livraison-d-t-o";
import {TagBoxComponent} from "../../../../shared/ui/tag-box/tag-box.component";
import {ClientDTO} from "../../../../core/dtos/client-dto";
import {ClientsService} from "../../../../core/services/entities/clients.service";
import {LookUpComponent} from "../../../../shared/ui/lookup/lookup.component";

@Component({
  selector: 'yo-point-facturation-dialog',
  templateUrl: './point-facturation-edition-dialog.html',
  styleUrls: ['./point-facturation-edition-dialog.scss']
})
export class PointFacturationEditionDialogComponent implements OnInit, OnDestroy {

  @ViewChild("plcList") plcListTagBox: TagBoxComponent;
  @ViewChild("clientLookup") clientLookup: LookUpComponent;

  pointFacturation: PointFacturationDto;

  form: FormGroup = new FormGroup({});

  formContact1: FormGroup;
  formContact2: FormGroup;
  formAdresse: FormGroup = new FormGroup({});


  dialogTitle: String;

  displayDialog = false;

  allPlcs: PointDeLivraisonDTO[] = [];
  selectedPlcs: number[] = [];

  allClients: ClientDTO[] = [];
  selectedClient: ClientDTO;

  subOpenDialog: Subscription
  subPlcs: Subscription;
  subClients: Subscription;

  pointDeFacturationEntityName = 'PointDeFacturation';

  constructor(private toastSvc: ToastService,
              public facturationClientSvc: FacturationClientService,
              public pointDeLivraisonSvc: PointDeLivraisonService,
              public clientSvc: ClientsService,
              public utilsSvc: UtilsService,
              public gfs: GenericFormService) {
  }

  ngOnInit(): void {
    this.globalInit();
    this.openDialogEditionSubscription();
  }

  globalInit = (): void => {
    this.selectedPlcs = this.utilsSvc.isCollectionNullOrEmpty(this.pointFacturation?.pointLivraisonClientList) ? [] : this.pointFacturation?.pointLivraisonClientList.map(plc => plc.id);
    this.selectedClient = this.pointFacturation?.client;
    this.initAllPlcs();
    this.initAllClients();
    this.initForm();
  }

  openDialogEditionSubscription = (): void => {
    this.subOpenDialog = this.facturationClientSvc.openDialogEditionPointFacturation$
      .subscribe((pf: PointFacturationDto) => {
        this.displayDialog = true;
        if (!pf) {
          this.pointFacturation = new PointFacturationDto();
          this.pointFacturation.id = 0;
          this.dialogTitle = 'Création d\'un point de facturation';
        } else {
          this.pointFacturation = pf;
          this.dialogTitle = 'Modification d\'un point de facturation';
        }
        this.globalInit();
      });
  };

  initAllClients = (): void => {
    this.subClients = this.clientSvc.findAllClients().subscribe((response) => {
      this.allClients = response.allClientsWithPointsDeLivraisonActifs;
    });
  };

  closeDialog = (): void => {
    this.displayDialog = false;
  };

  initAllPlcs = (): void => {
    this.subPlcs = this.pointDeLivraisonSvc.findAllPlcList(this.selectedClient ? this.selectedClient.id : null, true,
      this.selectedClient ? this.selectedPlcs : []).subscribe(response => {
      this.allPlcs = response.allPointLivraison;
    });
  }

  isFormsValide = (): boolean => {
    return this.form.valid &&
      this.formContact1.valid &&
      this.formContact2.valid &&
      this.formAdresse.valid &&
      this.clientLookup.getSelectedItem() !== null &&
      this.plcListTagBox.getSelectedItems().length > 0
}

  save = (): void => {
    if (this.isFormsValide()) {
      const adresseClient = this.gfs.mapFormToDTO(this.formAdresse) as AdresseDTO;
      const contact1 = this.gfs.mapFormToDTO(this.formContact1) as ContactDTO;
      const contact2 = this.gfs.mapFormToDTO(this.formContact2) as ContactDTO;
      adresseClient.id = this.pointFacturation?.adresse?.id;
      contact1.id = this.pointFacturation?.contact1?.id;
      contact2.id = this.pointFacturation?.contact2?.id;

      this.pointFacturation.libelle = this.form.controls['libelle'].value;
      this.pointFacturation.code = this.form.controls['code'].value;
      this.pointFacturation.actif = this.form.controls['actif'].value;
      this.pointFacturation.synthetique = this.form.controls['synthetique'].value;
      this.pointFacturation.delaiPaiement = this.form.controls['delaiPaiement'].value;
      this.pointFacturation.contact1 = contact1;
      this.pointFacturation.contact2 = contact2;
      this.pointFacturation.adresse = adresseClient;
      this.pointFacturation.pointLivraisonClientList = this.plcListTagBox.getSelectedItems();
      this.pointFacturation.client = this.clientLookup.getSelectedItem();

      // le site de l'adresse doit être le meme que site du client
      this.pointFacturation.adresse.site = this.pointFacturation.client.site;
      this.pointFacturation.adresse.entityName = this.pointDeFacturationEntityName;

      this.facturationClientSvc.savePointFacturation(this.pointFacturation).subscribe((response) => {
        if (!this.utilsSvc.isResponseSupplierError(response)) {
          const isUpdate = this.pointFacturation.id !== null && this.pointFacturation.id !== undefined && this.pointFacturation.id !== 0;
          this.facturationClientSvc.announcePointFacturationSaved(response.one, isUpdate);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Point de facturation enregistré avec succès`);
        }
      })
      this.closeDialog();
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  initForm = (): void => {
    this.form = new FormGroup({
      libelle: new FormControl(this.pointFacturation?.libelle, [Validators.required]),
      code: new FormControl(this.pointFacturation?.code, [Validators.required]),
      actif: new FormControl(this.pointFacturation?.actif != null ? this.pointFacturation.actif : true),
      delaiPaiement: new FormControl(this.pointFacturation?.delaiPaiement, [Validators.required]),
      synthetique: new FormControl(this.pointFacturation?.synthetique != null ? this.pointFacturation.synthetique : true)
    });

    this.formAdresse = new FormGroup({
      nom: new FormControl(this.pointFacturation?.adresse?.nom, [Validators.required]),
      adresse1: new FormControl(this.pointFacturation?.adresse?.adresse1, [Validators.required]),
      adresse2: new FormControl(this.pointFacturation?.adresse?.adresse2, ),
      adresse3: new FormControl(this.pointFacturation?.adresse?.adresse3, ),
      telephone: new FormControl(this.pointFacturation?.adresse?.telephone, [Validators.required]),
      fax: new FormControl(this.pointFacturation?.adresse?.fax, ),
      codePostal: new FormControl(this.pointFacturation?.adresse?.codePostal, [Validators.required]),
      ville: new FormControl(this.pointFacturation?.adresse?.ville, [Validators.required]),
      email: new FormControl(this.pointFacturation?.adresse?.email, [Validators.required]),
    });

    this.formContact1 = this.buildContactForm(this.pointFacturation?.contact1);
    this.formContact2 = this.buildContactForm(this.pointFacturation?.contact2);
  };

  buildContactForm = (contact: ContactDTO) => {
     return new FormGroup({
      nom: new FormControl(contact?.nom, ),
      prenom: new FormControl(contact?.prenom, ),
      telephone1: new FormControl(contact?.telephone1, ),
      telephone2: new FormControl(contact?.telephone2, ),
      fax: new FormControl(contact?.fax, ),
      mail: new FormControl(contact?.mail, ),
      commentaires: new FormControl(contact?.commentaires, ),
    });
  }

  onChangeClient = (event: any) => {
    this.selectedClient = this.allClients.find(client => client.id === event);
    this.initAllPlcs();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialog);
    this.utilsSvc.unsubscribe(this.subPlcs);
    this.utilsSvc.unsubscribe(this.subClients);
  }

}
