<dx-popup
  [width]="1200"
  [height]="700"
  title="Associations modèles conditionnements plats (conditionnements variantes) / prestations"
  [showTitle]="true"
  (onHiding)="closeDialog()"
  [(visible)]="openDialog"
  [resizeEnabled]="true">
  <div *ngIf="loading" id="loading-container">
    <div id="indicator-container">
      <dx-load-indicator></dx-load-indicator>
      <span>En cours de chargement ...</span>
    </div>
  </div>
  <form *ngIf="!loading" [formGroup]="form" (ngSubmit)="save()" novalidate id="container">
    <div id="lists-container">
      <div class="list-container custom-card-body">
        <yo-tree-list
          [data]="mcpCvList"
          key="value"
          displayValue="label"
          childValue="items"
          mode="groupedList"
          [selectedItems]="mcpCvSelectedList"
          returnData = true
          (onValueChange)="mcpCvSelectionChanged($event)">
          <dxo-selection mode="multiple" [recursive]="true"></dxo-selection>
          <dxi-column dataField="libelle" caption="Tout sélectionner"></dxi-column>
        </yo-tree-list>
      </div>
      <div class="list-container custom-card-body">
        <yo-list
          #pList
          [data]="prestationsList"
          [selectedItems]="prestationsSelectedList"
          [searchEnabled]="true"
          [selectionMode]="'all'"
          (onValueChange)="prestationSelectionChanged($event)">
        </yo-list>
      </div>
    </div>

    <div class="custom-button-container-right">
      <yo-button
        title="Enregistrer"
        type="submit"
        iconClass="fas fa-save"
        buttonClass="cta-success"
        [disabled]="!canModify">
      </yo-button>
    </div>

  </form>
</dx-popup>
