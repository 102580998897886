<div id="adding-area">
  <div id="adding-area-container">
    <div id="adding-area-content" class="border-like-dx {{isAddAreaCollapsed ? 'collapsed' : ''}}">
      <dx-tab-panel (onTitleClick)="onTabChange($event)">
        <dxi-item title="Offre alimentaire">
          <div class="line">
            <yo-tagbox
              [data]="hierarchy?.offresAlimentaire"
              [selectedItems]="selectedOffreAlimentaireList"
              (onValueChange)="updateValueOffreAlimentaire($event, cellTypes.OFFRE_ALIMENTAIRE)"
              placeholder="Offre alimentaire">
            </yo-tagbox>
            <yo-tagbox
              [data]="prestationList"
              [selectedItems]="selectedPrestationList"
              (onValueChange)="updateValueOffreAlimentaire($event, cellTypes.PRESTATION)"
              [disabled]="!isParentSelected(selectedOffreAlimentaireList)"
              placeholder="Prestation">
            </yo-tagbox>
            <yo-tagbox
              [data]="repasList"
              [selectedItems]="selectedRepasList"
              (onValueChange)="updateValueOffreAlimentaire($event, cellTypes.REPAS)"
              [disabled]="!isParentSelected(selectedPrestationList)"
              placeholder="Repas">
            </yo-tagbox>
          </div>
          <div class="line">
            <yo-tagbox
              [data]="regimeList"
              [selectedItems]="selectedRegimeList"
              (onValueChange)="updateValueOffreAlimentaire($event, cellTypes.REGIME)"
              [disabled]="!isParentSelected(selectedPrestationList)"
              placeholder="Régime">
            </yo-tagbox>
            <yo-tagbox
              [data]="pointFacturationList"
              [selectedItems]="selectedPointFacturationList"
              (onValueChange)="updateValueOffreAlimentaire($event, cellTypes.POINT_FACTURATION)"
              [disabled]="!isParentSelected(selectedPrestationList)"
              placeholder="Point de facturation">
            </yo-tagbox>
            <yo-tagbox
              [data]="pointLivraisonList"
              [selectedItems]="selectedPointLivraisonList"
              (onValueChange)="updateValueOffreAlimentaire($event, cellTypes.POINT_LIVRAISON)"
              [disabled]="!isParentSelected(selectedPointFacturationList)"
              placeholder="Point de livraison">
            </yo-tagbox>
          </div>
        </dxi-item>
        <dxi-item title="Client"
                  onClick="onTabChange($event)">
          <div class="line">
            <yo-tagbox
              [data]="pointFacturationList"
              [selectedItems]="selectedPointFacturationList"
              (onValueChange)="updateValueClient($event, cellTypes.POINT_FACTURATION)"
              placeholder="Point de facturation">
            </yo-tagbox>
            <yo-tagbox
              [data]="pointLivraisonList"
              [selectedItems]="selectedPointLivraisonList"
              (onValueChange)="updateValueClient($event, cellTypes.POINT_LIVRAISON)"
              [disabled]="!isParentSelected(selectedPointFacturationList)"
              placeholder="Point de livraison">
            </yo-tagbox>
            <yo-tagbox
              [data]="hierarchy?.offresAlimentaire"
              [selectedItems]="selectedOffreAlimentaireList"
              (onValueChange)="updateValueClient($event, cellTypes.OFFRE_ALIMENTAIRE)"
              [disabled]="!isParentSelected(selectedPointLivraisonList)"
              placeholder="Offre alimentaire">
            </yo-tagbox>
          </div>
          <div class="line">
            <yo-tagbox
              [data]="prestationList"
              [selectedItems]="selectedPrestationList"
              (onValueChange)="updateValueClient($event, cellTypes.PRESTATION)"
              [disabled]="!isParentSelected(selectedOffreAlimentaireList)"
              placeholder="Prestation">
            </yo-tagbox>
            <yo-tagbox
              [data]="repasList"
              [selectedItems]="selectedRepasList"
              (onValueChange)="updateValueClient($event, cellTypes.REPAS)"
              [disabled]="!isParentSelected(selectedPrestationList)"
              placeholder="Repas">
            </yo-tagbox>
            <yo-tagbox
              [data]="regimeList"
              [selectedItems]="selectedRegimeList"
              (onValueChange)="updateValueClient($event, cellTypes.REGIME)"
              [disabled]="!isParentSelected(selectedPrestationList)"
              placeholder="Régime">
            </yo-tagbox>
          </div>
        </dxi-item>
      </dx-tab-panel>

      <div class="line custom-button-container-right">
        <yo-button
          iconClass="fa fa-save"
          label="Ajouter"
          tooltip="Ajouter les lignes de paramétrage"
          buttonClass="cta-success"
          [disabled]="!isReadyToAdd()"
          (onClick)="addLignesParametrage()">
        </yo-button>
        <yo-button
          iconClass="fa fa-times"
          tooltip="Réduire la zone d'ajout de lignes de paramétrage"
          label="Fermer"
          buttonClass="cta-inner-delete"
          (onClick)="reduceAddArea()">
        </yo-button>
      </div>
    </div>
  </div>
</div>

<yo-ligne-parametrage-data-grid
  #grid
  class="grid-container {{isAddAreaCollapsed ? '' : 'mg-t-10'}}"
  [lignes]="ligneParametrageList">
  <div data-position="left">
    <yo-button
      [style.display]="isAddAreaCollapsed ? '' : 'none'"
      label="CRÉER"
      tooltip="Créer un nouveau paramétrage"
      iconClass="fa fa-plus"
      (onClick)="expandAddArea()">
    </yo-button>
  </div>

  <div data-position="right" class="custom-button-container">
    <yo-button
      tooltip="Modifier les lignes de paramétrage sélectionnées"
      iconClass="fa fa-edit"
      [disabled]="!isRowSelected()"
      (onClick)="openModificationPopup()">
    </yo-button>
    <yo-button
      iconClass="fa fa-save"
      tooltip="Enregistrer les lignes de paramétrage"
      buttonClass="cta-success"
      [disabled]="!gridChanges.hasChanges()"
      (onClick)="save()">
    </yo-button>
    <yo-button
      iconClass="fa fa-trash"
      tooltip="Supprimer les lignes de paramétrage sélectionnées"
      buttonClass="cta-inner-delete"
      [disabled]="!isRowSelected()"
      (onClick)="delete()">
    </yo-button>
  </div>
</yo-ligne-parametrage-data-grid>

<dx-popup
  title="Paramétrage des éléments à facturer"
  [visible]="isEditLinesPopupDisplayed"
  width="1200"
  height="700"
  minWidth="600"
  resizeEnabled="true"
  (onHiding)="closeEditLinesPopup()">
  <div class="full-size">
    <yo-ligne-parametrage-data-grid
      #gridPopup
      class="grid-container"
      [lignes]="ligneToAddList">
      <div data-position="right" class="custom-button-container">
        <yo-button
          tooltip="Modifier les lignes de paramétrage sélectionnées"
          iconClass="fa fa-edit"
          [disabled]="!isPopupRowSelected()"
          (onClick)="openModificationPopup()">
        </yo-button>
        <yo-button
          iconClass="fa fa-save"
          tooltip="Enregistrer les lignes de paramétrage"
          buttonClass="cta-success"
          [disabled]="!canAddValues()"
          (onClick)="saveEditedLines()">
        </yo-button>
        <yo-button
          iconClass="fa fa-trash"
          tooltip="Supprimer les lignes de paramétrage sélectionnées"
          buttonClass="cta-inner-delete"
          [disabled]="!isPopupRowSelected()"
          (onClick)="delete()">
        </yo-button>
      </div>
    </yo-ligne-parametrage-data-grid>
  </div>
</dx-popup>

<dx-popup
  title="Modification de masse"
  [visible]="isModificationPopupDisplayed"
  width="auto"
  height="auto"
  minWidth="600"
  (onHiding)="closeModificationPopup()">
  <div class="dialog-line">
    <div class="label">Élément facturable</div>
    <div class="value">
      <yo-lookup
        [data]="elementFacturableList"
        [selectedItem]="selectedElementFacturable"
        autoSelect="false"
        (onValueChange)="updateElementFacturable($event)"
        placeholder="Sélectionner l'élément facturable ...">
      </yo-lookup>
    </div>
  </div>
  <div class="dialog-line">
    <div class="label">Taxe</div>
    <div class="value">
      <yo-lookup
        [data]="taxeList"
        [selectedItem]="selectedTaxe"
        autoSelect="false"
        (onValueChange)="updateTaxe($event)"
        placeholder="Sélectionner la taxe ...">
      </yo-lookup>
    </div>
  </div>
  <div class="dialog-line">
    <div class="label">Prix de vente HT</div>
    <div class="value">
      <dx-number-box
        [(value)]="selectedPrix">
      </dx-number-box>
    </div>
  </div>
  <div class="dialog-line">
    <div class="custom-button-container-right">
      <yo-button
        iconClass="fa fa-check"
        buttonClass="cta-success"
        (onClick)="applyModifications()">
      </yo-button>
    </div>
  </div>
</dx-popup>
